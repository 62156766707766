@use 'theme.scss' as *;
@use 'typography.scss' as *;
@use "sass:math";

$toast-width: 642px;
$sidenav-width: 221px;

.k-notification-wrap > .k-notification-content {
  width: 95%;
}

.k-notification-group {
  // Center based on the width of the sidenav and toast message
  margin-left: math.div($toast-width, -2) + math.div($sidenav-width, 2) !important;
}

// Standard width from UX is that Toast messages are 642px.
.k-notification {
  width: 642px;
}

.k-notification-success {
  color: $white;
  background-color: $green;
  border-color: $green;
}

.k-notification-info {
  color: $white;
  background-color: $blue;
  border-color: $blue;
}

.k-notification-error {
  color: $white;
  background-color: $red;
  border-color: $red;
}

.validation-error-toast {
  &__container {
    display: flex;
    justify-content: space-around;
    padding: 0 5px 0 5px;

    > div {
      margin: 0 5px 0 5px;
    }
  }

  &__content {
    display: flex;
  }

  &__text {
    width: 432px;
  }

  &__edit-button {
    @include global-h6;
    color: $white;

    &:hover {
      cursor: pointer;
    }
  }

  &__text-title {
    font-weight: bold;
  }
}
