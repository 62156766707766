@font-face {
  font-family: 'icons';
  src: url('./icons.eot?acb3d39ab5286cd5c2ccb60d5b1a13e4?#iefix') format('embedded-opentype'),
    url('./icons.woff2?acb3d39ab5286cd5c2ccb60d5b1a13e4') format('woff2'),
    url('./icons.woff?acb3d39ab5286cd5c2ccb60d5b1a13e4') format('woff'),
    url('./icons.ttf?acb3d39ab5286cd5c2ccb60d5b1a13e4') format('truetype'),
    url('./icons.svg?acb3d39ab5286cd5c2ccb60d5b1a13e4#icons') format('svg');
}

i[class^='icon-']:before,
i[class*=' icon-']:before {
  font-family: icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-accounting:before {
  content: '\f101';
}
.icon-add-user:before {
  content: '\f102';
}
.icon-add:before {
  content: '\f103';
}
.icon-admin:before {
  content: '\f104';
}
.icon-administration:before {
  content: '\f105';
}
.icon-arrow-left:before {
  content: '\f106';
}
.icon-arrow-right:before {
  content: '\f107';
}
.icon-auth-post:before {
  content: '\f108';
}
.icon-award:before {
  content: '\f109';
}
.icon-block:before {
  content: '\f10a';
}
.icon-calendar:before {
  content: '\f10b';
}
.icon-capacity-release:before {
  content: '\f10c';
}
.icon-carat-down:before {
  content: '\f10d';
}
.icon-carat-left:before {
  content: '\f10e';
}
.icon-carat-right:before {
  content: '\f10f';
}
.icon-carat-up:before {
  content: '\f110';
}
.icon-changes:before {
  content: '\f111';
}
.icon-charge-type:before {
  content: '\f112';
}
.icon-check:before {
  content: '\f113';
}
.icon-chev-left:before {
  content: '\f114';
}
.icon-chev-right:before {
  content: '\f115';
}
.icon-circle-cross:before {
  content: '\f116';
}
.icon-clock:before {
  content: '\f117';
}
.icon-clone:before {
  content: '\f118';
}
.icon-close-lg:before {
  content: '\f119';
}
.icon-close-small:before {
  content: '\f11a';
}
.icon-close:before {
  content: '\f11b';
}
.icon-collapse:before {
  content: '\f11c';
}
.icon-contact:before {
  content: '\f11d';
}
.icon-contracts:before {
  content: '\f11e';
}
.icon-data-sync-lock:before {
  content: '\f11f';
}
.icon-data-sync:before {
  content: '\f120';
}
.icon-dead:before {
  content: '\f121';
}
.icon-delete:before {
  content: '\f122';
}
.icon-dollar:before {
  content: '\f123';
}
.icon-double-arrow-up:before {
  content: '\f124';
}
.icon-download:before {
  content: '\f125';
}
.icon-drought:before {
  content: '\f126';
}
.icon-edit:before {
  content: '\f127';
}
.icon-entity-management:before {
  content: '\f128';
}
.icon-entity:before {
  content: '\f129';
}
.icon-error:before {
  content: '\f12a';
}
.icon-expand:before {
  content: '\f12b';
}
.icon-export:before {
  content: '\f12c';
}
.icon-file-close:before {
  content: '\f12d';
}
.icon-file-zipped-download:before {
  content: '\f12e';
}
.icon-filter:before {
  content: '\f12f';
}
.icon-flag:before {
  content: '\f130';
}
.icon-flowgas:before {
  content: '\f131';
}
.icon-flowing-gas:before {
  content: '\f132';
}
.icon-hide:before {
  content: '\f133';
}
.icon-high-demand:before {
  content: '\f134';
}
.icon-home:before {
  content: '\f135';
}
.icon-id-card:before {
  content: '\f136';
}
.icon-info-post:before {
  content: '\f137';
}
.icon-info:before {
  content: '\f138';
}
.icon-information:before {
  content: '\f139';
}
.icon-informational-postings:before {
  content: '\f13a';
}
.icon-invoicing:before {
  content: '\f13b';
}
.icon-key:before {
  content: '\f13c';
}
.icon-leave:before {
  content: '\f13d';
}
.icon-left:before {
  content: '\f13e';
}
.icon-line:before {
  content: '\f13f';
}
.icon-link-line:before {
  content: '\f140';
}
.icon-link-solid:before {
  content: '\f141';
}
.icon-load-template:before {
  content: '\f142';
}
.icon-location:before {
  content: '\f143';
}
.icon-locked-view:before {
  content: '\f144';
}
.icon-locked:before {
  content: '\f145';
}
.icon-logout:before {
  content: '\f146';
}
.icon-low-demand:before {
  content: '\f147';
}
.icon-medium-demand:before {
  content: '\f148';
}
.icon-move-cursor:before {
  content: '\f149';
}
.icon-move-horiz:before {
  content: '\f14a';
}
.icon-navigation:before {
  content: '\f14b';
}
.icon-nominations:before {
  content: '\f14c';
}
.icon-notifications:before {
  content: '\f14d';
}
.icon-open-new:before {
  content: '\f14e';
}
.icon-oval:before {
  content: '\f14f';
}
.icon-overflow-horizontal:before {
  content: '\f150';
}
.icon-overflow-vertical:before {
  content: '\f151';
}
.icon-override:before {
  content: '\f152';
}
.icon-paper-plane:before {
  content: '\f153';
}
.icon-party:before {
  content: '\f154';
}
.icon-pause:before {
  content: '\f155';
}
.icon-pipeline-info:before {
  content: '\f156';
}
.icon-pipeline-model:before {
  content: '\f157';
}
.icon-play:before {
  content: '\f158';
}
.icon-price:before {
  content: '\f159';
}
.icon-properties:before {
  content: '\f15a';
}
.icon-rates-discounts:before {
  content: '\f15b';
}
.icon-reduced:before {
  content: '\f15c';
}
.icon-reject:before {
  content: '\f15d';
}
.icon-reload:before {
  content: '\f15e';
}
.icon-reports:before {
  content: '\f15f';
}
.icon-reput-award:before {
  content: '\f160';
}
.icon-right:before {
  content: '\f161';
}
.icon-search:before {
  content: '\f162';
}
.icon-setup:before {
  content: '\f163';
}
.icon-shield-user:before {
  content: '\f164';
}
.icon-short-term-marketplace:before {
  content: '\f165';
}
.icon-simple-arrow-left:before {
  content: '\f166';
}
.icon-simple-arrow-right:before {
  content: '\f167';
}
.icon-sitemap:before {
  content: '\f168';
}
.icon-sort:before {
  content: '\f169';
}
.icon-success:before {
  content: '\f16a';
}
.icon-system-planning:before {
  content: '\f16b';
}
.icon-thumbs-up:before {
  content: '\f16c';
}
.icon-unlocked:before {
  content: '\f16d';
}
.icon-user:before {
  content: '\f16e';
}
.icon-view-lock:before {
  content: '\f16f';
}
.icon-view:before {
  content: '\f170';
}
.icon-warning:before {
  content: '\f171';
}
.icon-withdraw-bid:before {
  content: '\f172';
}
.icon-withdraw:before {
  content: '\f173';
}
.icon-write:before {
  content: '\f174';
}
