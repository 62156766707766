@use 'theme.scss' as *;
@use 'typography.scss' as *;

input:disabled,
textarea:disabled {
  background-color: $light-blue-gray;
  color: $dark-blue-gray !important;
}

input {
  // common font color for input states that have values or are in error
  //color: $dark-blue !important;
  @include global-input-text;
}
