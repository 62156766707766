@mixin icon-accounting {
  font-family: icons !important;
  content: '\f101';
}
@mixin icon-add-user {
  font-family: icons !important;
  content: '\f102';
}
@mixin icon-add {
  font-family: icons !important;
  content: '\f103';
}
@mixin icon-admin {
  font-family: icons !important;
  content: '\f104';
}
@mixin icon-administration {
  font-family: icons !important;
  content: '\f105';
}
@mixin icon-arrow-left {
  font-family: icons !important;
  content: '\f106';
}
@mixin icon-arrow-right {
  font-family: icons !important;
  content: '\f107';
}
@mixin icon-auth-post {
  font-family: icons !important;
  content: '\f108';
}
@mixin icon-award {
  font-family: icons !important;
  content: '\f109';
}
@mixin icon-block {
  font-family: icons !important;
  content: '\f10a';
}
@mixin icon-calendar {
  font-family: icons !important;
  content: '\f10b';
}
@mixin icon-capacity-release {
  font-family: icons !important;
  content: '\f10c';
}
@mixin icon-carat-down {
  font-family: icons !important;
  content: '\f10d';
}
@mixin icon-carat-left {
  font-family: icons !important;
  content: '\f10e';
}
@mixin icon-carat-right {
  font-family: icons !important;
  content: '\f10f';
}
@mixin icon-carat-up {
  font-family: icons !important;
  content: '\f110';
}
@mixin icon-changes {
  font-family: icons !important;
  content: '\f111';
}
@mixin icon-charge-type {
  font-family: icons !important;
  content: '\f112';
}
@mixin icon-check {
  font-family: icons !important;
  content: '\f113';
}
@mixin icon-chev-left {
  font-family: icons !important;
  content: '\f114';
}
@mixin icon-chev-right {
  font-family: icons !important;
  content: '\f115';
}
@mixin icon-circle-cross {
  font-family: icons !important;
  content: '\f116';
}
@mixin icon-clock {
  font-family: icons !important;
  content: '\f117';
}
@mixin icon-clone {
  font-family: icons !important;
  content: '\f118';
}
@mixin icon-close-lg {
  font-family: icons !important;
  content: '\f119';
}
@mixin icon-close-small {
  font-family: icons !important;
  content: '\f11a';
}
@mixin icon-close {
  font-family: icons !important;
  content: '\f11b';
}
@mixin icon-collapse {
  font-family: icons !important;
  content: '\f11c';
}
@mixin icon-contact {
  font-family: icons !important;
  content: '\f11d';
}
@mixin icon-contracts {
  font-family: icons !important;
  content: '\f11e';
}
@mixin icon-data-sync-lock {
  font-family: icons !important;
  content: '\f11f';
}
@mixin icon-data-sync {
  font-family: icons !important;
  content: '\f120';
}
@mixin icon-dead {
  font-family: icons !important;
  content: '\f121';
}
@mixin icon-delete {
  font-family: icons !important;
  content: '\f122';
}
@mixin icon-dollar {
  font-family: icons !important;
  content: '\f123';
}
@mixin icon-double-arrow-up {
  font-family: icons !important;
  content: '\f124';
}
@mixin icon-download {
  font-family: icons !important;
  content: '\f125';
}
@mixin icon-drought {
  font-family: icons !important;
  content: '\f126';
}
@mixin icon-edit {
  font-family: icons !important;
  content: '\f127';
}
@mixin icon-entity-management {
  font-family: icons !important;
  content: '\f128';
}
@mixin icon-entity {
  font-family: icons !important;
  content: '\f129';
}
@mixin icon-error {
  font-family: icons !important;
  content: '\f12a';
}
@mixin icon-expand {
  font-family: icons !important;
  content: '\f12b';
}
@mixin icon-export {
  font-family: icons !important;
  content: '\f12c';
}
@mixin icon-file-close {
  font-family: icons !important;
  content: '\f12d';
}
@mixin icon-file-zipped-download {
  font-family: icons !important;
  content: '\f12e';
}
@mixin icon-filter {
  font-family: icons !important;
  content: '\f12f';
}
@mixin icon-flag {
  font-family: icons !important;
  content: '\f130';
}
@mixin icon-flowgas {
  font-family: icons !important;
  content: '\f131';
}
@mixin icon-flowing-gas {
  font-family: icons !important;
  content: '\f132';
}
@mixin icon-hide {
  font-family: icons !important;
  content: '\f133';
}
@mixin icon-high-demand {
  font-family: icons !important;
  content: '\f134';
}
@mixin icon-home {
  font-family: icons !important;
  content: '\f135';
}
@mixin icon-id-card {
  font-family: icons !important;
  content: '\f136';
}
@mixin icon-info-post {
  font-family: icons !important;
  content: '\f137';
}
@mixin icon-info {
  font-family: icons !important;
  content: '\f138';
}
@mixin icon-information {
  font-family: icons !important;
  content: '\f139';
}
@mixin icon-informational-postings {
  font-family: icons !important;
  content: '\f13a';
}
@mixin icon-invoicing {
  font-family: icons !important;
  content: '\f13b';
}
@mixin icon-key {
  font-family: icons !important;
  content: '\f13c';
}
@mixin icon-leave {
  font-family: icons !important;
  content: '\f13d';
}
@mixin icon-left {
  font-family: icons !important;
  content: '\f13e';
}
@mixin icon-line {
  font-family: icons !important;
  content: '\f13f';
}
@mixin icon-link-line {
  font-family: icons !important;
  content: '\f140';
}
@mixin icon-link-solid {
  font-family: icons !important;
  content: '\f141';
}
@mixin icon-load-template {
  font-family: icons !important;
  content: '\f142';
}
@mixin icon-location {
  font-family: icons !important;
  content: '\f143';
}
@mixin icon-locked-view {
  font-family: icons !important;
  content: '\f144';
}
@mixin icon-locked {
  font-family: icons !important;
  content: '\f145';
}
@mixin icon-logout {
  font-family: icons !important;
  content: '\f146';
}
@mixin icon-low-demand {
  font-family: icons !important;
  content: '\f147';
}
@mixin icon-medium-demand {
  font-family: icons !important;
  content: '\f148';
}
@mixin icon-move-cursor {
  font-family: icons !important;
  content: '\f149';
}
@mixin icon-move-horiz {
  font-family: icons !important;
  content: '\f14a';
}
@mixin icon-navigation {
  font-family: icons !important;
  content: '\f14b';
}
@mixin icon-nominations {
  font-family: icons !important;
  content: '\f14c';
}
@mixin icon-notifications {
  font-family: icons !important;
  content: '\f14d';
}
@mixin icon-open-new {
  font-family: icons !important;
  content: '\f14e';
}
@mixin icon-oval {
  font-family: icons !important;
  content: '\f14f';
}
@mixin icon-overflow-horizontal {
  font-family: icons !important;
  content: '\f150';
}
@mixin icon-overflow-vertical {
  font-family: icons !important;
  content: '\f151';
}
@mixin icon-override {
  font-family: icons !important;
  content: '\f152';
}
@mixin icon-paper-plane {
  font-family: icons !important;
  content: '\f153';
}
@mixin icon-party {
  font-family: icons !important;
  content: '\f154';
}
@mixin icon-pause {
  font-family: icons !important;
  content: '\f155';
}
@mixin icon-pipeline-info {
  font-family: icons !important;
  content: '\f156';
}
@mixin icon-pipeline-model {
  font-family: icons !important;
  content: '\f157';
}
@mixin icon-play {
  font-family: icons !important;
  content: '\f158';
}
@mixin icon-price {
  font-family: icons !important;
  content: '\f159';
}
@mixin icon-properties {
  font-family: icons !important;
  content: '\f15a';
}
@mixin icon-rates-discounts {
  font-family: icons !important;
  content: '\f15b';
}
@mixin icon-reduced {
  font-family: icons !important;
  content: '\f15c';
}
@mixin icon-reject {
  font-family: icons !important;
  content: '\f15d';
}
@mixin icon-reload {
  font-family: icons !important;
  content: '\f15e';
}
@mixin icon-reports {
  font-family: icons !important;
  content: '\f15f';
}
@mixin icon-reput-award {
  font-family: icons !important;
  content: '\f160';
}
@mixin icon-right {
  font-family: icons !important;
  content: '\f161';
}
@mixin icon-search {
  font-family: icons !important;
  content: '\f162';
}
@mixin icon-setup {
  font-family: icons !important;
  content: '\f163';
}
@mixin icon-shield-user {
  font-family: icons !important;
  content: '\f164';
}
@mixin icon-short-term-marketplace {
  font-family: icons !important;
  content: '\f165';
}
@mixin icon-simple-arrow-left {
  font-family: icons !important;
  content: '\f166';
}
@mixin icon-simple-arrow-right {
  font-family: icons !important;
  content: '\f167';
}
@mixin icon-sitemap {
  font-family: icons !important;
  content: '\f168';
}
@mixin icon-sort {
  font-family: icons !important;
  content: '\f169';
}
@mixin icon-success {
  font-family: icons !important;
  content: '\f16a';
}
@mixin icon-system-planning {
  font-family: icons !important;
  content: '\f16b';
}
@mixin icon-thumbs-up {
  font-family: icons !important;
  content: '\f16c';
}
@mixin icon-unlocked {
  font-family: icons !important;
  content: '\f16d';
}
@mixin icon-user {
  font-family: icons !important;
  content: '\f16e';
}
@mixin icon-view-lock {
  font-family: icons !important;
  content: '\f16f';
}
@mixin icon-view {
  font-family: icons !important;
  content: '\f170';
}
@mixin icon-warning {
  font-family: icons !important;
  content: '\f171';
}
@mixin icon-withdraw-bid {
  font-family: icons !important;
  content: '\f172';
}
@mixin icon-withdraw {
  font-family: icons !important;
  content: '\f173';
}
@mixin icon-write {
  font-family: icons !important;
  content: '\f174';
}
