@use 'theme.scss' as *;
@use 'shared/icons/output/icons.scss' as *;

.k-datepicker {
  width: 100%;
  box-sizing: content-box;
  .k-select {
    background-color: $white !important;
    border-left: none !important;
    color: $dark-blue;
    .k-icon {
      color: $primary-blue;
      font-size: $default-icon-size;
    }
    .k-i-calendar::before {
      color: $primary-blue;
      @include icon-calendar;
    }
  }
  .k-input {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    height: 46px; // Removed !important from this style, if this breaks something, please include a comment for why it was needed.
    &[aria-valuetext=''] {
      //Yes, this is hacky. Kendo overrides the normal placeholder.
      color: $dark-blue-gray;
    }
  }
}
