//Fonts

// General UI
$default-icon-size: 27px;
$default-border-radius: 4px;
$defaul-transistion: all 0.3s ease-in-out;
$default-box-shadow: 0 0 15px 0 rgba(0, 50, 168, 0.1);

//Colors
// Use the EColor Enum in conjunction with importing auto-color or auto-background-color
// to make things a lot easier to configure with color inputs.
$primary-blue: #223c9b;
$dark-blue: #2c3e50;
$white: #ffffff;

$red: #f43653;
$teal: #1abc9c;
$green: #7ed322;
$yellow: #f5a623;
$light-blue: #2980b9; //Not a color?
$blue: #4a90e2;
$focus: #b1d2fa;
$hover: #dce9f9;
$purple: #9b59b6;

$dark-gray: #bfc5ca;
$gray: #dbdee3;
$light-gray: #f7f8fc;

$darker-blue-gray: #60707f;
$dark-blue-gray: #abb4be;
$blue-gray: #e1e4ed;
$light-blue-gray: #eef1f6;

// TODO: Figure out how to do this with a css function
@mixin auto-color {
  &.primary-blue {
    color: $primary-blue;
  }
  &.dark-blue {
    color: $dark-blue;
  }
  &.white {
    color: $white;
  }
  &.red {
    color: $red;
  }
  &.teal {
    color: $teal;
  }
  &.green {
    color: $green;
  }
  &.yellow {
    color: $yellow;
  }
  &.light-blue {
    // Remove
    color: $light-blue;
  }
  &.blue {
    color: $blue;
  }
  &.focus {
    color: $blue;
  }
  &.hover {
    color: $blue;
  }
  &.purple {
    color: $purple;
  }
  &.dark-gray {
    color: $dark-gray;
  }
  &.gray {
    color: $gray;
  }
  &.light-gray {
    color: $light-gray;
  }
  &.darker-blue-gray {
    color: $darker-blue-gray;
  }
  &.dark-blue-gray {
    color: $dark-blue-gray;
  }
  &.blue-gray {
    color: $blue-gray;
  }
  &.light-blue-gray {
    color: $light-blue-gray;
  }
}

@mixin auto-background-color {
  &.primary-blue {
    background-color: $primary-blue;
  }
  &.dark-blue {
    background-color: $dark-blue;
  }
  &.white {
    background-color: $white;
  }
  &.red {
    background-color: $red;
  }
  &.teal {
    background-color: $teal;
  }
  &.green {
    background-color: $green;
  }
  &.yellow {
    background-color: $yellow;
  }
  &.light-blue {
    // Remove
    background-color: $light-blue;
  }
  &.blue {
    background-color: $blue;
  }
  &.focus {
    background-color: $focus;
  }
  &.hover {
    background-color: $hover;
  }
  &.purple {
    background-color: $purple;
  }
  &.dark-gray {
    background-color: $dark-gray;
  }
  &.gray {
    background-color: $gray;
  }
  &.light-gray {
    background-color: $light-gray;
  }
  &.darker-blue-gray {
    background-color: $darker-blue-gray;
  }
  &.dark-blue-gray {
    background-color: $dark-blue-gray;
  }
  &.blue-gray {
    background-color: $blue-gray;
  }
  &.light-blue-gray {
    background-color: $light-blue-gray;
  }
}

// Color as if transparent over white
$place-holder-grey: rgb(171, 180, 190);

@mixin focus-outline {
  outline-style: solid;
  outline-color: $primary-blue;
  outline-width: 1px;
}

@mixin global-gms-container {
  background: $white;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
}

@mixin card-label {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.9px;
  display: block;
  min-height: 15px;
  text-transform: uppercase;
}

@mixin popover-body {
  border: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
}
