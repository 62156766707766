@use 'theme.scss' as *;
@use 'typography.scss' as *;

.k-tabstrip-top > .k-tabstrip-items .k-item {
  background-color: transparent;
  box-sizing: border-box;
  width: 25%;
  min-height: 50px;
  color: $dark-blue;
  font-size: 16px;
  font-weight: normal;

  &.k-state-active {
    background-color: $white;
    .k-link {
      color: $dark-blue;
      font-size: 16px;
      font-weight: bold;
    }
    box-shadow: 0 -8px 15px -7px rgba(0, 50, 168, 0.1);
  }
}

.k-tabstrip-items .k-link {
  margin: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

div#k-tabstrip-tabpanel-0 {
  background-color: $white;
  box-shadow: 0 -8px 15px -7px rgba(0, 50, 168, 0.1);
  padding: 0;
}

div#k-tabstrip-tabpanel-1 {
  background-color: $white;
  box-shadow: 0 -8px 15px -7px rgba(0, 50, 168, 0.1);
  padding: 0;
}

div#k-tabstrip-tabpanel-2 {
  background-color: $white;
  box-shadow: 0 -8px 15px -7px rgba(0, 50, 168, 0.1);
  padding: 0;
}

div#k-tabstrip-tabpanel-3 {
  background-color: $white;
  box-shadow: 0 -8px 15px -7px rgba(0, 50, 168, 0.1);
  padding: 0;
}
