.k-numeric-wrap {
  .k-select {
    display: none;
  }
}

.k-numerictextbox {
  width: 100%;
  height: 48px;
  .k-input {
    height: 48px;
  }
}
