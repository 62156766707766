/* You can add global styles to this file, and also import other style files */
@use 'shared/icons/output/icons.css' as *;
@use 'shared/styles/autocomplete.scss' as *;
@use 'shared/styles/card.scss' as *;
@use 'shared/styles/switch.scss' as *;
@use 'shared/styles/datepicker.scss' as *;
@use 'shared/styles/input.scss' as *;
@use 'shared/styles/numerictextbox.scss' as *;
@use 'shared/styles/dropdown.scss' as *;
@use 'shared/styles/toast.scss' as *;
@use 'shared/styles/loadingspinner.scss' as *;
@use 'shared/styles/tab-view.scss' as *;
@use 'shared/styles/textbox.scss' as *;
@use 'shared/styles/masked-input.scss' as *;
@use 'shared/styles/labels.scss' as *;
@use 'typography.scss' as *;
@use 'theme.scss' as *;
@use 'z-indexes.scss' as *;
@use 'bootstrap/scss/bootstrap-grid.scss' as *;

body {
  margin: 0;
  font-family: Roboto, sans-serif;
  height: 100%;
}

html {
  height: 100%;
}

.display-1 {
  @include global-display-1;
}

.display-2 {
  @include global-display-2;
}

h1,
.h1 {
  @include global-h1;
}

h2,
.h2 {
  @include global-h2;
}

h3,
.h3 {
  @include global-h3;
}

h4,
.h4 {
  @include global-h4;
}

h5,
.h5 {
  @include global-h5;
}

h6,
.h6 {
  @include global-h6;
}

p {
  margin-top: 16px;
  margin-bottom: 16px;
}

a,
.global-link {
  @include global-link;
}

body {
  @include global-body;
}

.global-body {
  @include global-body;
}

.global-body-large {
  @include global-body-large;
}

.global-body-small {
  @include global-body-small;
}

.global-body-extra-small {
  @include global-body-extra-small;
}

.global-error-text {
  @include global-error-text;
}

.global-table-data {
  @include global-table-data;
}

.global-table-column-header {
  @include global-table-column-header;
}

.global-placeholder-text {
  @include global-placeholder-text;
}

.global-input-text {
  @include global-input-text;
}

.global-label-text,
label {
  @include global-label-text;
}

.global-gms-container {
  @include global-gms-container;
}

.global-dropdown-menu-large {
  @include dropdown-menu-large;
}

.grid {
  box-sizing: border-box;

  .input-wrapper {
    padding-right: 16px;
    padding-bottom: 13px;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
}

.toaster-call-to-action {
  @include toaster-call-to-action;
}

kendo-datepicker.k-state-disabled {
  background-color: $light-blue-gray;
  span {
    background-color: transparent !important;
  }
}

kendo-numerictextbox {
  span.k-state-disabled {
    background-color: $light-blue-gray !important;
  }
}

kendo-popup {
  .k-item {
    font-size: 14px;
  }
  .sub-text {
    color: $darker-blue-gray;
    font-size: 10px;
    display: block;
  }

  .large-sub-text {
    color: $darker-blue-gray;
    font-size: 10px;
    display: block;
  }
}

kendo-textbox-container {
  .k-textarea {
    padding: 8px;
  }
}

.k-dialog-actions {
  border: none;
  border-width: 0px;
  justify-content: flex-end;
  padding: 24px;
  .ng-star-inserted {
    flex: none;
  }
}

*:focus {
  outline: none;
}

.form-field-label {
  @include form-field-label;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.delete-button {
  font-size: 24px;
  color: #223c9b;
}
