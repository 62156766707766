@use 'theme.scss' as *;

@mixin header-common {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $dark-blue;
}

@mixin global-display-1 {
  @include header-common;
  font-size: 80px;
  font-weight: 300;
}

@mixin global-display-2 {
  @include header-common;
  font-size: 64px;
  font-weight: 300;
}

@mixin global-h1 {
  @include header-common;
  font-size: 40px;
  font-weight: 300;
}

@mixin global-h2 {
  @include header-common;
  font-size: 32px;
  font-weight: 300;
}

@mixin global-h3 {
  @include header-common;
  font-size: 24px;
  font-weight: 300;
}

@mixin global-h4 {
  @include header-common;
  font-size: 18px;
  font-weight: 300;
}

@mixin global-h5 {
  @include header-common;
  font-size: 16px;
  font-weight: 300;
}

@mixin global-h6 {
  @include header-common;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 1.33;
  letter-spacing: 0.9px;
  text-transform: uppercase;
}

@mixin body-common {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  line-height: 1.5;
  color: $dark-blue;
}

@mixin global-body-large {
  @include body-common;
  font-size: 16px;
  font-weight: normal;
}

@mixin global-body {
  @include body-common;
  font-size: 14px;
  font-weight: normal;
}

@mixin global-body-small {
  @include body-common;
  font-size: 12px;
  font-weight: normal;
}

@mixin global-body-extra-small {
  @include body-common;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

@mixin global-link {
  text-decoration: none;
  color: $blue;
}

@mixin global-error-text {
  font-family: Roboto, sans-serif;
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: $red;
}

@mixin global-table-data {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

@mixin global-table-column-header {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-transform: uppercase;
  color: $dark-blue;
  width: 100%;
}

@mixin global-placeholder-text {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $dark-blue-gray;
}

@mixin global-input-text {
  font-family: Roboto, sans-serif;
  font-size: 14px !important;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $dark-blue !important;
}

@mixin global-label-text {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: $dark-blue;
}

@mixin global-label-text-alt {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.86px;
  display: block;
  text-transform: uppercase;
  font-weight: bold;
}

@mixin dropdown-menu-large {
  width: 24px;
  height: 22px;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $dark-blue;
}

@mixin toaster-call-to-action {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 10px;
  color: #ffffff;
  line-height: 15px;
  letter-spacing: 1px;
}

@mixin tertiary-nav-item {
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $dark-blue;
}

@mixin global-form-label {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.86px;
  display: block;
  text-transform: uppercase;
}

@mixin global-button-text {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
}
