@use 'theme.scss' as *;

._card-header {
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  height: 24px;
  line-height: normal;
  letter-spacing: normal;
  color: $dark-blue;
  display: inline-block;
}

._card-sub-header {
  height: 15px;
  font-family: Roboto, sans-serif;
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 1px;
  color: $dark-blue;
  text-transform: uppercase;
  margin-left: 8px;
}
