@use 'theme.scss' as *;

.k-dropdown .k-dropdown-wrap {
  background-color: $white !important;
  border: 1px solid $gray;
  border-radius: 3px;
}

.k-dropdown .k-state-focused {
  box-shadow: none !important;
  border: 1px solid $primary-blue;
}

.k-dropdown {
  width: 100%;
}

kendo-multiselect.k-multiselect:after {
  color: $primary-blue;
}

kendo-dropdownlist.k-picker-solid.k-dropdownlist {
  box-shadow: none !important;

  background-color: $white;
  border: 1px solid $gray;
  border-radius: 3px;

  .k-input-value-text div {
    display: inline-block;
  }
}

kendo-dropdownlist.k-focus.k-picker-solid:focus-within:not(.ng-invalid.ng-touched) {
  border: 1px solid $primary-blue;
}
